import React, {useState} from "react";
import styled from "styled-components";
import tw from "twin.macro";
import {ReactComponent as SvgDotPatternIcon} from "../images/club/dot-pattern.svg"

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-primary-500 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }

  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }

  input, textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-400 italic`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-5/12 flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;

const SvgDotPattern1 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`

export default () => {

    // eslint-disable-next-line no-unused-vars
    const [name, setName] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [mail, setMail] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [message, setMessage] = useState('');

    const sendEmail = (e) => {
        e.preventDefault();
        setName("");
        setMail("");
        setMessage("");
        window.alert("Deine Nachricht konnte leider nicht verschickt werden! Bitte schreibe direkt eine Nachricht an: oesgviktoria08dortmund@gmail.com");
    };

    return (
        <Container>
            <Content id="contact">
                <FormContainer>
                    <div tw="mx-auto max-w-4xl">
                        <h2>Kontaktiere uns</h2>
                        <form onSubmit={sendEmail}>
                            <TwoColumn>
                                <Column>
                                    <InputContainer>
                                        <Label htmlFor="name-input">Dein Name</Label>
                                        <Input id="name-input" type="text" name="name"
                                               placeholder="z.B. Max Mustermann"
                                               onInput={(event) => setName(event.target.value)}/>
                                    </InputContainer>
                                    <InputContainer>
                                        <Label htmlFor="email-input">Deine E-Mail-Adresse</Label>
                                        <Input id="email-input" type="email" name="email"
                                               placeholder="z.B.maxmustermann@mail.com"
                                               onInput={(event) => setMail(event.target.value)}/>
                                    </InputContainer>
                                </Column>
                                <Column>
                                    <InputContainer tw="flex-1">
                                        <Label htmlFor="name-input">Deine Nachricht</Label>
                                        <TextArea id="message-input" name="message"
                                                  placeholder="z.B. Hallo! Wann findet das nächste Training der ersten Mannschaft statt?"
                                                  onInput={(event) => setMessage(event.target.value)}/>
                                    </InputContainer>
                                </Column>
                            </TwoColumn>

                            <SubmitButton type="submit" value="Submit">Senden</SubmitButton>
                        </form>
                    </div>
                    <SvgDotPattern1/>
                </FormContainer>
            </Content>
        </Container>
    );
};
