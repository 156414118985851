import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/club/svg-decorator-blob-8.svg";
import {ReactComponent as SvgDotPatternIcon} from "../images/club/dot-pattern.svg";

const Subheading = tw(SubheadingBase)`mb-4 text-center`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;
const Description2 = tw(SectionDescription)`w-full text-center mt-16`;

const Column = tw.div`flex flex-col items-center`;
const HeaderContent = tw.div``;

const FAQSContainer = tw.dl`mt-12 max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);


const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

const PrimaryAction = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-10 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;

export default ({
                    subheading = "FAQs",
                    heading = "Noch Fragen ?",
                    description = "Hier findest du sämtliche Fragen und Antworten u.a. bezüglich einer Anmeldung.",
                    faqs = [
                        {
                            question: "Was muss man bei einer Neuanmeldung mitnehmen ?",
                            answer:
                                "Wir brauchen die vollständig ausgefüllten Anmeldedokumente (Download siehe Link oben) inkl. Passbild. Die Formulare hierfür liegene im Vereinsheim aus, können aber auch schon zuhause gedruckt werden.",
                        },
                        {
                            question: "Was benötigt man bei einer Anmeldung mit Vereinswechsel ?",
                            answer:
                                "In diesem Fall brauchen wir deinen Spielerpass vom alter Verein inkl. Abmeldung, neuem Passbild und Freigabe auf der Passrückseite."
                        },
                        {
                            question: "Wie melde ich mich ab ?",
                            answer:
                                "Schade, dass du uns schon verlassen möchtest. Falls du Redebedarf und wir dich noch umstimmen können, sprich einfach direkt unseren Vorstand. Wir werden sicherlich eine gemeinsame Lösung finden. Falls dennoch deine Entscheidung feststeht, für eine Abmeldung schickst du einfach einen Abmeldeantrag per Einschreiben (z.B. per Postkarte) an die Adresse im Impressum. Bitte teile uns noch über das Kontaktformular dein Feedback mit. Nur so können wir uns ständig verbessern. Vielen Dank für deine Kooperation! "
                        },
                    ]
                }) => {
    const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

    const toggleQuestion = questionIndex => {
        if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
        else setActiveQuestionIndex(questionIndex);
    };

    return (
        <Container id="faq">
            <ContentWithPaddingXl>
                <Column>
                    <HeaderContent>
                        {subheading && <Subheading>{subheading}</Subheading>}
                        <Heading>{heading}</Heading>
                        {description && <Description>{description}</Description>}
                        <Description>
                            <PrimaryAction>
                                <a href="https://drive.google.com/file/d/1YjZ2TGqIFpRznSdeJyTS9mHtKRWAnmSN/view?usp=sharing" target="_blank" rel="noreferrer">
                                    Zu den Anmeldeformularen
                                </a>
                            </PrimaryAction>
                        </Description>
                    </HeaderContent>
                    <FAQSContainer>
                        {faqs.map((faq, index) => (
                            <FAQ
                                key={index}
                                onClick={() => {
                                    toggleQuestion(index);
                                }}
                                className="group"
                            >
                                <Question>
                                    <QuestionText>{faq.question}</QuestionText>
                                    <QuestionToggleIcon
                                        variants={{
                                            collapsed: { rotate: 0 },
                                            open: { rotate: -180 }
                                        }}
                                        initial="collapsed"
                                        animate={activeQuestionIndex === index ? "open" : "collapsed"}
                                        transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
                                    >
                                        <ChevronDownIcon />
                                    </QuestionToggleIcon>
                                </Question>
                                <Answer
                                    variants={{
                                        open: { opacity: 1, height: "auto", marginTop: "16px" },
                                        collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                                    }}
                                    initial="collapsed"
                                    animate={activeQuestionIndex === index ? "open" : "collapsed"}
                                    transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                                >
                                    {faq.answer}
                                </Answer>
                            </FAQ>
                        ))}
                    </FAQSContainer>
                    <HeaderContent>
                        <Description2>Wir konnten deine Frage nicht beantworten? Dann kontaktiere uns gerne direkt mit deiner Frage!</Description2>
                    </HeaderContent>
                </Column>
            </ContentWithPaddingXl>
            <SvgDotPattern2 />
            <DecoratorBlob2 />
        </Container>
    );
};
