import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ClubPage from "./demos/ClubPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";

export default function App() {

  return (
      <Router>
        <Switch>
            <Route path="/policy">
                <PrivacyPolicy />
            </Route>
            <Route path="/impressum">
                <TermsOfService />
            </Route>
            <Route path="/">
                <ClubPage ></ClubPage>
            </Route>
        </Switch>
      </Router>
  );
}