import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import MouseAnimation from "../styles/MouseAnimtaion.module.css";

import Header, { NavLink, NavLinks, LogoLink, NavToggle, DesktopNavLinks } from "./headers/light.js";
import logo from "../images/club/ösg-logo.webp";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover h-screen min-h-144`}
  background-image: url("https://jimdo-storage.freetls.fastly.net/image/323061291/414e7293-5986-4eec-afff-5fbaaf8a12dc.jpg?format=pjpg&quality=80,90&auto=webp&disable=upscale&width=1920&height=1485");
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-75`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const Text = tw.div`text-gray-400 text-lg max-w-lg text-center mt-6`;

const PrimaryAction = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;

export default () => {
    const navLinks = [
        <NavLinks key={1}>
            <NavLink href="#about-us">
                Über uns
            </NavLink>
            <NavLink href="#teams">
                Teams
            </NavLink>
            <NavLink href="#platz">
                Anlage
            </NavLink>
            <NavLink href="#vorstand">
                Vorstand
            </NavLink>
            <NavLink href="#sponsors">
                Sponsoren
            </NavLink>
            <NavLink href="#faq">
                FAQ
            </NavLink>
            <NavLink href="#contact">
                Kontakt
            </NavLink>
            <NavLink href="/impressum">
                Impressum
            </NavLink>
        </NavLinks>,
        <NavLinks key={2}>
            <LogoLink href="/">
                <img src={logo} alt="logo" />
            </LogoLink>
        </NavLinks>
    ];

    return (
        <Container>
            <OpacityOverlay />
            <HeroContainer>
                <StyledHeader links={navLinks} />
                <Content>
                    <Heading>
                        Willkommen bei der
                        <br />
                        ÖSG Viktoria 08 Dortmund!
                    </Heading>
                    <Text>Die Östliche Sportgemeinschaft Viktoria 08 Dortmund, entstand aus der Straßensportgemeinschaft “Untere Kaiserstraße, Roonstraße, von-der-Tann Straße” im Jahre 1908. Seit dieser Zeit, bietet der Verein Sportmöglichkeiten in der Östlichen Innenstadt und Körne an.</Text>
                    <NavLink href="#contact">
                        <PrimaryAction>Kontaktieren Sie uns</PrimaryAction>
                    </NavLink>
                    <div className={MouseAnimation.mouse}>
                        <div className={MouseAnimation.dot}></div>
                    </div>
                </Content>
            </HeroContainer>
        </Container>
    );
};
