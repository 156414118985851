import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "../images/club/ösg-logo.webp";
import {ReactComponent as LinkedIn} from "../images/club/linkedin-icon.svg";

const Container = tw.div`relative bg-gray-200 -mx-8 -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-between`;

const Column = tw.div`md:w-1/5`;
const WideColumn = tw(Column)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black text-primary-500`;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 `;

const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-700 text-gray-100 hover:bg-gray-900 transition duration-300 mr-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default () => {
    return (
        <Container>
            <FiveColumns>
                <WideColumn>
                    <LogoContainer>
                        <LogoImg src={LogoImage} />
                        <LogoText>ÖSG VIKTORIA 08 DORTMUND</LogoText>
                    </LogoContainer>
                    <CompanyDescription>
                        ÖSG Viktoria 08 e.V. Dortmund
                        <br/>
                        © Official website
                        <br/>
                        <br/>
                        Developed by Emir Gülec
                        <br/>
                        <br/>
                        <SocialLink target="_blank" href="https://www.linkedin.com/in/emirguelec">
                            <LinkedIn />
                        </SocialLink>
                    </CompanyDescription>
                </WideColumn>
                <Column>
                    <ColumnHeading>Schnellzugriff</ColumnHeading>
                    <LinkList>
                        <LinkListItem>
                            <Link href="/#about-us">Über uns</Link>
                        </LinkListItem>
                        <LinkListItem>
                            <Link href="/#teams">Teams</Link>
                        </LinkListItem>
                        <LinkListItem>
                            <Link href="/#platz">Anlage</Link>
                        </LinkListItem>
                        <LinkListItem>
                            <Link href="/#vorstand">Vorstand</Link>
                        </LinkListItem>
                        <LinkListItem>
                            <Link href="/#sponsors">Sponsoren</Link>
                        </LinkListItem>
                        <LinkListItem>
                            <Link href="/#contact">Kontakt</Link>
                        </LinkListItem>
                    </LinkList>
                </Column>
                <Column>
                    <ColumnHeading>Vereinspräsenz</ColumnHeading>
                    <LinkList>
                        <LinkListItem>
                            <Link target="_blank" href="https://www.instagram.com/osg_viktoria/?hl=de">Instagram</Link>
                        </LinkListItem>
                        <LinkListItem>
                            <Link target="_blank" href="https://www.fussball.de/verein/oesg-viktoria-08-dortmund-westfalen/-/id/00ES8GN8N4000099VV0AG08LVUPGND5I#!/">Fussball.de</Link>
                        </LinkListItem>
                        <LinkListItem>
                            <Link target="_blank" href="https://vereinslinie.com/oesg-viktoria-08-dortmund">Vereinslinie</Link>
                        </LinkListItem>
                    </LinkList>
                </Column>
                <Column>
                    <ColumnHeading>Rechtliches</ColumnHeading>
                    <LinkList>
                        <LinkListItem>
                            <Link href="/impressum">Impressum</Link>
                        </LinkListItem>
                        <LinkListItem>
                            <Link href="/policy">Datenschutz</Link>
                        </LinkListItem>
                    </LinkList>
                </Column>
            </FiveColumns>
        </Container>
    );
};
