import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import {SectionHeading as HeadingTitle} from "components/misc/Headings.js";

import { ReactComponent as SvgDecoratorBlob3 } from "../images/club/svg-decorator-blob-3.svg";

const Container = tw.div`relative py-20 md:py-24`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto`}
`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }

  .subdescription {
    ${tw`mt-3 font-thin text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

const HeadingInfoContainer = tw.div`flex flex-col items-center mt-8`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

export default () => {
    /*
     * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
     *  1) imageSrc - the image shown at the top of the card
     *  2) title - the title of the card
     *  3) description - the description of the card
     *  If a key for a particular card is not provided, a default value is used
     */

    const cards = [
        {
            title: "ÖSG VIKTORIA 08 DORTMUND",
            subtitle: "1. Mannschaft",
            description: "Kreisliga B4",
            trainer: "Kapishan Kamalakumar",
            email: "oesgviktoria08dortmund@gmail.com",
            number: "0176 612 690 40",
            time1: "Mittwoch 19.30 – 21.00 Uhr",
            time2: "Freitag 19.00 – 20.30 Uhr",
        },
        {
            title: "ÖSG VIKTORIA 08 II DORTMUND",
            subtitle: "2. Mannschaft",
            description: "Kreisliga C3",
            trainer: "Rik Gellermann",
            email: "oesgviktoria08@gmail.com",
            time1: "Mittwoch 18.30 – 20.00 Uhr",
            time2: "Freitag 18.30 – 20.00 Uhr",
        },
        {
            title: "ÖSG VIKTORIA 08 III DORTMUND",
            subtitle: "3. Mannschaft",
            description: "Kreisliga C4",
            trainer: "Mamadou Aliou Bah",
            email: "bahalioum84@gmail.com",
            time1: "Mittwoch 18.30 – 20.00 Uhr",
            time2: "Freitag 19.30 – 21.00 Uhr",
        },
        {
            title: "ÖSG VIKTORIA 08 IV DORTMUND",
            subtitle: "4. Mannschaft",
            description: "Kreisliga C5",
            trainer: "Andrés Báez",
            email: "atletico.latino.dortmund@gmail.com",
            number: "0176 / 977 172 68",
            time1: "Mittwoch 19.30 – 21.00 Uhr",
            time2: "Samstags 10:30 – 12:30 Uhr",
        },
        {
            title: "ÖSG VIKTORIA 08 - ALTHERREN",
            subtitle: "Altherrenmannschaft",
            description: "Nicht für den Spielbetrieb angemeldet",
            trainer: "Thomas Packheiser",
            email: "thomas.packheiser@grunenthal.com",
            number: "0173 569 507 3",
            time1: "Montag 19.00 – 20.30 Uhr",
        },
    ];

    const junioren = [
        {
            title: "ÖSG VIKTORIA 08 DORTMUND",
            subtitle: "E-Junioren",
            description: "Finalrunde - Staffel 03",
            trainer: "Andreas Beckmann & Nuno Gondinho Dos Santos",
            email: "freitag.nicole@t-online.de",
            time1: "Mittwoch 17:00 – 18:00",
            time2: "Freitag 17:00 – 18:30"
        },
        {
            title: "ÖSG VIKTORIA 08 DORTMUND",
            subtitle: "F-Junioren",
            description: "Finalrunde - Staffel 12",
            trainer: "Andreas Beckmann, Tobias Nieswand & Nuno Godhino Dos Santos",
            email: "freitag.nicole@t-online.de",
            time1: "Mittwoch 17:00 – 18:00",
            time2: "Freitag 17:00 – 18:30"
        },
        {
            title: "ÖSG VIKTORIA 08 DORTMUND",
            subtitle: "G-Junioren",
            description: "Nicht für den Spielbetrieb angemeldet",
            trainer: "Andreas Beckmann & Tobias Nieswand",
            email: "freitag.nicole@t-online.de",
            time1: "Mittwoch 17:00 – 18:00",
        },
    ];

    return (
        <Container id="teams">
            <HeadingInfoContainer>
                <HeadingTitle>Unsere Teams</HeadingTitle>
                <HeadingDescription>
                    <p>Senioren</p>
                </HeadingDescription>
            </HeadingInfoContainer>
            <ThreeColumnContainer>
                {cards.map((card, i) => (
                    <Column key={i}>
                        <Card>
                            <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.subtitle}
                </p>
                <p className="description">
                  {card.description || "-"}
                </p>
                <details>
                    <summary className="subdescription">Weitere Informationen</summary>
                    <p className="description">
                      Trainer:
                     <p className="subdescription">{card.trainer || "-"}</p>
                    </p>
                    <p className="description">
                      E-Mail:
                        <p className="subdescription">{card.email || "-"}</p>
                    </p>
                    <p className="description">
                      Telefon: <p className="subdescription">{card.number || "-"}</p>
                    </p>
                     <p className="description">
                      Trainingszeiten:
                        <p className="subdescription">{card.time1 || "-"}</p>
                         <p className="subdescription">{card.time2 || ""}</p>
                    </p>
                </details>
              </span>
                        </Card>
                    </Column>
                ))}
            </ThreeColumnContainer>
            <HeadingInfoContainer>
                <HeadingDescription>
                    <p>Junioren</p>
                </HeadingDescription>
            </HeadingInfoContainer>
            <ThreeColumnContainer>
                {junioren.map((card, i) => (
                    <Column key={i}>
                        <Card>
                            <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.subtitle}
                </p>
                <p className="description">
                  {card.description || "-"}
                </p>
                 <details>
                    <summary className="subdescription">Weitere Informationen</summary>
                    <p className="description">
                      Trainer:
                     <p className="subdescription">{card.trainer || "-"}</p>
                    </p>
                    <p className="description">
                      E-Mail:
                        <p className="subdescription">{card.email || "-"}</p>
                    </p>
                    <p className="description">
                      Telefon: <p className="subdescription">{card.number || "-"}</p>
                    </p>
                     <p className="description">
                      Trainingszeiten:
                        <p className="subdescription">{card.time1 || "-"}</p>
                         <p className="subdescription">{card.time2 || ""}</p>
                    </p>
                </details>
              </span>
                        </Card>
                    </Column>
                ))}
            </ThreeColumnContainer>
            <DecoratorBlob />
        </Container>
    );
};
