import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import ClubAboutUsSection from "components/ClubAboutUsSection.js";
import ClubContact from "components/ClubContact.js";
import ClubFooter from "components/ClubFooter.js";
import ClubHeroSection from "../components/ClubHeroSection";
import ClubBoard from "../components/ClubBoard";
import ClubTeams from "../components/ClubTeams";
import ClubSponsors from "../components/ClubSponsors";
import ClubLocation from "../components/ClubLocation";
import ClubFaq from "../components/ClubFaq";
import ClubVereinslinie from "../components/ClubVereinslinie";

export default () => (
    <AnimationRevealPage disabled>
        <ClubHeroSection />
        <ClubAboutUsSection />
        <ClubTeams />
        <ClubLocation />
        <ClubBoard/>
        <ClubSponsors />
        <ClubVereinslinie />
        <ClubFaq />
        <ClubContact />
        <ClubFooter />
    </AnimationRevealPage>
);
