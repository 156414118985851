import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../images/club/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "./misc/Headings.js";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;
const HeadingMainDescription = tw.p`my-4 text-lg leading-loose text-gray-900 text-center max-w-lg`;
const HeadingSubDescription = tw.p`mt-20 text-lg leading-loose text-gray-900 text-center max-w-lg `;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-lg leading-loose text-justify`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
    const cards = [
        {
            imageSrc:
                "https://cdn.pixabay.com/photo/2016/03/23/12/26/football-1274661_1280.jpg",
            subtitle: "Unser",
            title: "Aller Leidenschaft",
            description:
                "Sport ist unsere gemeinsame Leidenschaft und die ursprüngliche Motivation für diesen Verein. Ob Fußball, Volleyball oder Leichtathletik - bei uns findet jede Person ihre sportliche Leidenschaft und wird von erfahrenen Betreuenden angeleitet und gefördert.",
            url: "https://timerse.com"
        },
        {
            imageSrc:
                "https://jimdo-storage.freetls.fastly.net/image/323058330/28f92349-1a28-4220-b299-855a0b81b5d8.jpg?format=pjpg&quality=80,90&auto=webp&disable=upscale&width=1939&height=2560",
            subtitle: "Unsere",
            title: "Gemeinsamen Werte",
            description:
                "Unser Verein ist mehr als nur eine Sportgemeinschaft. Wir zeichnen uns durch ein starkes Zusammengehörigkeitsgefühl aus, das von gegenseitiger Hilfe und Unterstützung geprägt ist. Bei uns steht nicht nur der sportliche Erfolg im Vordergrund, sondern auch der Spaß an der Bewegung und das gemeinsame Erleben von Freude und Erfolgen. Auch wenn jede einzelne Person in unserem Verein einzigartig ist, teilen wir alle die gleichen Werte.",
        },

        {
            imageSrc:
                "https://jimdo-storage.freetls.fastly.net/image/317802924/a8bd7a00-5bdc-4404-898e-902c9f9722d0.jpg?format=pjpg&quality=80,90&auto=webp&disable=upscale&width=1024&height=1024&trim=0,200,0,200",
            subtitle: "Unser",
            title: "Vereinsleben",
            description:
                "Auf dem Platz sind wir leidenschaftliche Sportler:innen. Doch dabei allein bleibt es nicht. Auch abseits des Platzes veranstalten wir öfter kleinere Team-Events wie zum Beispiel entspannte Grillabende. Sollte es mal zu schulischen oder persönlichen Problemen kommen, so stehen unsere Mitglieder gerne mit Rat und Tat zur Seite, denn das zeichnet unseren starken Zusammenhalt aus.",
            url: "https://timerse.com"
        },
    ];

    return (
        <Container>
            <SingleColumn id="about-us">
                <HeadingInfoContainer>
                    <HeadingTitle>Über unseren Verein</HeadingTitle>
                    <HeadingMainDescription>
                        Wir sind ein Verein, der sich für ein offenes und inklusives Miteinander einsetzt und allen Menschen, unabhängig von ihrer Herkunft, ihrem Alter oder ihrem Geschlecht,
                        einen Ort des Willkommens und der Zugehörigkeit schaffen möchte.
                    </HeadingMainDescription>
                    <HeadingDescription>
                        Was zeichnet uns aus?
                    </HeadingDescription>
                </HeadingInfoContainer>

                <Content>
                    {cards.map((card, i) => (
                        <Card key={i} reversed={i % 2 === 1}>
                            <Image imageSrc={card.imageSrc} />
                            <Details>
                                <Subtitle>{card.subtitle}</Subtitle>
                                <Title>{card.title}</Title>
                                <Description>{card.description}</Description>
                            </Details>
                        </Card>
                    ))}
                </Content>

                <HeadingInfoContainer>
                    <HeadingSubDescription>
                        Wir sind stolz darauf, ein Verein zu sein, der für Toleranz, Respekt und Solidarität einsteht. Bei uns sind alle willkommen, die diese Werte teilen und sich aktiv dafür engagieren möchten.
                        Überzeuge dich selbst und schau gerne mal bei uns im Verein vorbei. Wir freuen uns auf deinen Besuch!
                    </HeadingSubDescription>
                </HeadingInfoContainer>
            </SingleColumn>
            <SvgDotPattern1 />
            <SvgDotPattern2 />
            <SvgDotPattern3 />
            <SvgDotPattern4 />
        </Container>
    );
};
