import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {SectionHeading as HeadingTitle, SectionHeading} from "components/misc/Headings.js";
import field from "images/club/training-platz.webp";
import { ReactComponent as SvgDotPatternIcon } from "../images/club/dot-pattern.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 lg:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
    tw`md:w-6/12 mt-8 md:mt-0 sm:mt-32`,
    props.textOnLeft ? tw`md:mr-8 lg:mr-16 md:order-first` : tw`md:ml-8 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
    tw`rounded bg-cover bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8`;

const Heading = tw(SectionHeading)`text-left text-2xl sm:text-3xl lg:text-4xl text-center md:text-left leading-tight mt-16`;
const Description = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-4`

const HeadingInfoContainer = tw.div`flex flex-col items-center mt-8`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Statistics = tw.div`mt-32 lg:mt-8 xl:mt-8 flex flex-wrap`
const Statistic = tw.div`text-lg sm:text-2xl lg:text-3xl w-1/2 mt-4 lg:mt-10 text-center md:text-left`
const Value = tw.div`font-bold text-primary-500`
const Key = tw.div`font-medium text-gray-700`

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;

const SvgDotPattern2 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`

const PrimaryAction = tw.button`rounded-full px-8 py-3 text-sm sm:text-base sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;

export default ({textOnLeft = false}) => {
    // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
    //Change the statistics variable as you like, add or delete objects
    const statistics = [
        {
            key: "Kapazität",
            value: "3000",
        },
        {
            key: "Untergrund",
            value: "Kunstrasen",
        },
        {
            key: "Laufbahn",
            value: "teilweise",
        },
        {
            key: "Flutlicht",
            value: "vorhanden",
        }
    ]

    return (
        <Container id="platz">
            <HeadingInfoContainer>
                <HeadingTitle>Unsere Sportanlage</HeadingTitle>
                <HeadingDescription>
                    <p>ÖSG Viktoria 08 Dortmund</p>
                    <p>Lippstädter Straße</p>
                    <p>44143 Dortmund</p>
                    <br/>
                    <PrimaryAction>
                        <a href="https://goo.gl/maps/qEqULVmVyPcFsczT6" target="_blank" rel="noreferrer">Öffne mit Google Maps</a>
                    </PrimaryAction>
                </HeadingDescription>
            </HeadingInfoContainer>
            <TwoColumn>
                <ImageColumn>
                    <Image>
                        <img src={field} alt=""/>
                    </Image>
                </ImageColumn>
                <TextColumn textOnLeft={textOnLeft}>
                    <TextContent>
                        <Statistics>
                            {statistics.map((statistic, index) => (
                                <Statistic key={index}>
                                    <Key>{statistic.key}</Key>
                                    <Value>{statistic.value}</Value>
                                </Statistic>
                            ))}
                        </Statistics>
                        <Heading>Parkmöglichkeiten</Heading>
                        <Description>
                            <p>An unserem Platz und in naher Umgebung gibt es reichlich Parkmöglichkeiten wie z.B.:</p>
                            <p>- auf der Hannöversche Straße (Nördlich vom Sportplatz)</p>
                            <p>- Park and Ride Parkplatz (Südwestlich vom Sportplatz)</p>
                        </Description>
                    </TextContent>
                </TextColumn>
            </TwoColumn>
            <SvgDotPattern1 />
            <SvgDotPattern2 />
        </Container>
    );
};
